<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div dir="rtl" class="fixed inset-0 z-10 overflow-y-auto" :class="theme">
    <div
      class="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="inline-block h-screen align-middle"></span>&#8203;

      <div
        class="inline-block w-10/12 overflow-hidden align-bottom transition-all transform bg-white shadow-xl rounded-mine font-mine sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="px-4 pt-5 pb-4 bg-white rounded-mine sm:p-6 sm:pb-4">
          <div class="text-center sm:mt-0 sm:text-right">
            <h3
              class="mb-4 text-lg font-bold leading-6 text-primary"
              id="modal-headline"
            >
              اختر منتج
            </h3>
            <div class="flex flex-col items-center justify-center">
              <div v-if="prod.variants" class="w-full mb-6 mr-2">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                >
                  {{ prod.variantTitle }}
                </label>

                <select
                  v-model="choice"
                  dir="rtl"
                  class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                >
                  <option
                    v-for="n in prod.variants"
                    v-bind:key="n.id"
                    :value="n"
                  >
                    <p>{{ n.name }}_</p>
                    <p>
                      {{
                        (parseFloat(prod.price) + parseFloat(n.price)).toFixed(
                          2
                        )
                      }}
                      ريال
                    </p>
                  </option>
                </select>
              </div>
              <div v-if="prod.addOns.length > 0" class="w-full mb-6 mr-2">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                >
                  إضافات
                </label>
                <label
                  v-for="a in prod.addOns"
                  v-bind:key="a.id"
                  class="flex items-center px-2 mb-2"
                >
                  <input
                    :value="a"
                    v-model="addOns"
                    type="checkbox"
                    class="w-4 h-4 border-gray-300 rounded text-secondary focus:ring-secondary"
                  />
                  <p class="mr-2 text-gray-700">{{ a.name }} +</p>
                  <p class="mr-2 text-gray-700">
                    {{ parseFloat(a.price).toFixed(2) }} ريال
                  </p>
                </label>
              </div>
              <div class="flex justify-between w-full">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                >
                  سعر المنتج
                </label>
                <div
                  class="text-lg font-medium text-center text-secondary font-mine"
                >
                  {{ currency() }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row">
          <span
            class="flex w-full mb-4 shadow-sm rounded-mine sm:ml-3 sm:w-auto"
          >
            <button
              @click="order"
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out rounded-mine bg-primary focus:outline-none sm:text-sm sm:leading-5"
            >
              أضف المنتج
            </button>
          </span>
          <span class="flex w-full mb-4 shadow-sm rounded-mine sm:w-auto">
            <button
              @click="hide"
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border text-primary border-primary rounded-mine focus:outline-none sm:text-sm sm:leading-5"
            >
              إلغاء
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "Modal",
  created: function () {
    if (this.prod.variants) this.choice = this.prod.variants[0];
  },
  props: { theme: String, prod: Object },
  data() {
    return {
      choice: null,
      addOns: [],
      item: {},
    };
  },
  methods: {
    hide() {
      console.log(JSON.stringify(this.addOns));
      this.$emit("hide");
    },
    order() {
      this.item.id = this.prod.id;
      this.item.name = this.prod.name;
      this.item.image = this.prod.image;
      this.item.status = this.prod.status;
      this.item.price = parseFloat(this.prod.price);
      if (this.prod.variants) {
        this.item.id += this.choice.id;
        this.item.name += "-" + this.choice.name;
        this.item.price += parseFloat(this.choice.price);
      }
      if (this.addOns.length > 0) {
        let iid = 0;
        for (let i = 0; i < this.addOns.length; i++) {
          iid += parseFloat(this.addOns[i].id);
          this.item.name += "-" + this.addOns[i].name;
          this.item.price += parseFloat(this.addOns[i].price);
        }
        this.item.id += "" + iid;
      }
      this.$emit("order", { ...this.item });
    },
    currency() {
      let price = parseFloat(this.prod.price);
      if (this.prod.variants) {
        price += parseFloat(this.choice.price);
      }
      if (this.addOns.length > 0) {
        for (let i = 0; i < this.addOns.length; i++) {
          price += parseFloat(this.addOns[i].price);
        }
      }
      return price.toFixed(2) + " ريال";
    },
  },
};
</script>
    <style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
