<template>
  <div
    dir="rtl"
    class="fixed inset-0 z-10 w-full overflow-y-auto"
    :class="theme"
  >
    <div
      class="flex flex-col items-center justify-center w-full h-screen text-center lg:flex-row"
    >
      <div
        class="flex-col items-center justify-center w-10/12 max-h-screen px-4 pt-4 pb-4 my-4 overflow-y-scroll transition-all transform bg-white shadow-xl rounded-mine md:w-7/12 lg:w-5/12"
      >
        <div class="relative mb-4 pb-1/1">
          <img
            class="absolute top-0 object-cover object-center w-full h-full rounded-mine"
            :src="product.image.url"
          />
        </div>
        <h1
          class="mb-2 text-xl font-semibold text-center font-mine md:text-2xl lg:text-2xl"
        >
          {{ product.name }}
        </h1>
        <p class="mb-2 text-lg font-medium text-center font-mine">
          {{ product.description }}
        </p>

        <p
          v-if="product.variants"
          class="mb-2 text-lg font-semibold text-center text-secondary font-mine"
        >
          {{ product.variantTitle }}
        </p>
        <div dir="rtl" class="grid grid-cols-2 gap-2">
          <div v-for="o in product.variants" v-bind:key="o.id">
            <p
              class="text-lg font-medium text-center text-white rounded-md font-mine bg-secondary"
            >
              {{ o.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-2/12 mb-4 transition-all transform bg-white rounded-full md:w-1/12 lg:w-1/12 lg:mr-4"
      >
        <svg
          @click="hide"
          xmlns="http://www.w3.org/2000/svg"
          class="w-full text-secondary icon icon-tabler icon-tabler-x"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: "Modal",

  props: { product: Object, theme: String },
  methods: {
    hide() {
      this.$emit("hide");
    },
    currency(price) {
      return parseFloat(price).toFixed(2) + " ريال";
    },
  },
};
</script>
    <style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
