
<template>
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
  >
    <div
      v-if="done"
      class="flex flex-col w-full h-full min-h-screen bg-gray-100"
      :class="shop.theme"
    >
      <div
        v-bind="shop"
        class="flex flex-col w-full py-4 mb-4 border-b-4 shadow-md bg-primary rounded-b-mine border-secondary justify-items-center"
      >
        <img
          class="top-0 self-center object-cover object-center w-20 h-20 mb-4 border-4 rounded-full border-secondary"
          :src="shop.image.url"
        />
        <h1
          dir="rtl"
          class="px-8 mb-2 text-3xl font-black text-center text-white font-mine"
        >
          {{ shop.name }}
        </h1>
        <h1
          dir="rtl"
          class="px-8 mb-3 text-xl font-black text-center text-white font-mine"
        >
          {{ shop.branch }}
        </h1>
        <div class="flex items-center justify-evenly">
          <button>
            <svg
              @click="QR()"
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 text-white icon icon-tabler icon-tabler-brand-snapchat"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="4" width="6" height="6" rx="1" />
              <line x1="7" y1="17" x2="7" y2="17.01" />
              <rect x="14" y="4" width="6" height="6" rx="1" />
              <line x1="7" y1="7" x2="7" y2="7.01" />
              <rect x="4" y="14" width="6" height="6" rx="1" />
              <line x1="17" y1="7" x2="17" y2="7.01" />
              <line x1="14" y1="14" x2="17" y2="14" />
              <line x1="20" y1="14" x2="20" y2="14.01" />
              <line x1="14" y1="14" x2="14" y2="17" />
              <line x1="14" y1="20" x2="17" y2="20" />
              <line x1="17" y1="17" x2="20" y2="17" />
              <line x1="20" y1="17" x2="20" y2="20" />
            </svg>
          </button>
          <button v-if="shop.social.snapchat">
            <svg
              @click="openSnap(shop.social.snapchat)"
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 text-white icon icon-tabler icon-tabler-brand-snapchat"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M16.882 7.842a4.882 4.882 0 0 0 -9.764 0c0 4.273 -.213 6.409 -4.118 8.118c2 .882 2 .882 3 3c3 0 4 2 6 2s3 -2 6 -2c1 -2.118 1 -2.118 3 -3c-3.906 -1.709 -4.118 -3.845 -4.118 -8.118zm-13.882 8.119c4 -2.118 4 -4.118 1 -7.118m17 7.118c-4 -2.118 -4 -4.118 -1 -7.118"
              />
            </svg>
          </button>
          <button v-if="shop.social.instagram">
            <svg
              @click="openInsta(shop.social.instagram)"
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 text-white icon icon-tabler icon-tabler-brand-instagram"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="4" y="4" width="16" height="16" rx="4" />
              <circle cx="12" cy="12" r="3" />
              <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
            </svg>
          </button>
          <button v-if="shop.social.whatsapp">
            <svg
              @click="openWhatsapp(shop.social.whatsapp)"
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 text-white icon icon-tabler icon-tabler-brand-whatsapp"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
              <path
                d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"
              />
            </svg>
          </button>
          <button v-if="shop.social.location">
            <svg
              @click="openLocation(shop.social.location)"
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 text-white icon icon-tabler icon-tabler-map-pin"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="11" r="3" />
              <path
                d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        class="flex flex-row-reverse items-center overflow-x-auto md:justify-center"
      >
        <div
          v-for="category in shop.categories"
          v-bind:key="category.id"
          @click="selectedCategory = category.id"
          :class="[
            selectedCategory === category.id
              ? 'justify-center items-center mx-1 px-3 py-1 rounded-mine text-lg md:text-xl font-mine  font-bold text-white bg-primary border border-primary whitespace-nowrap shadow-md'
              : 'justify-center items-center mx-1 px-3 py-1 rounded-mine text-lg md:text-xl font-mine font-bold text-primary bg-white border border-primary whitespace-nowrap',
          ]"
        >
          {{ category.value }}
        </div>
      </div>
      <div
        class="grid flex-1 grid-cols-1 gap-4 m-4 md:gap-6 md:grid-cols-2 md:m-8"
      >
        <div class="col-span-1 md:col-span-2">
          <div
            dir="rtl"
            class="grid grid-cols-2 gap-3 md:gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          >
            <div
              class="overflow-hidden bg-opacity-0 shadow-md max-w rounded-mine"
              v-for="product in filteredProducts"
              v-bind:key="product.id"
            >
              <div @click="toggleModal(product)" class="relative pb-1/1">
                <div
                  v-if="product.status === 'غير متوفر'"
                  class="absolute top-0 z-10 flex flex-col justify-center object-cover object-center w-full h-full text-center opacity-60 bg-primary"
                >
                  <p
                    class="font-black text-white opacity-100 font-mine text-md md:text-xl lg:text-xl"
                  >
                    المنتج غير متوفر
                  </p>
                </div>

                <img
                  class="absolute top-0 object-cover object-center w-full h-full"
                  :src="product.image.url"
                />
              </div>
              <div class="bg-white">
                <h1
                  class="px-1 pt-2 font-black text-center truncate font-mine text-md md:text-xl lg:text-xl"
                >
                  {{ product.name }}
                </h1>
                <p
                  class="pt-1 font-black text-center font-mine text-secondary text-md"
                >
                  {{ currency(product.price) }}
                </p>
                <button
                  @click="toggleModal(product)"
                  class="inline-flex items-center justify-center w-1/2 py-2 text-white rounded-tl-full bg-primary focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-1/3 icon icon-tabler icon-tabler-info-circle"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                    <polyline points="11 12 12 12 12 16 13 16" />
                  </svg>
                </button>
                <button
                  @click="addItem(product)"
                  class="inline-flex items-center justify-center w-1/2 py-2 text-white rounded-tr-full bg-primary focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-1/3 icon icon-tabler icon-tabler-shopping-cart"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="19" r="2" />
                    <circle cx="17" cy="19" r="2" />
                    <path
                      d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <transition
            enter-active-class="transition duration-300 ease-in"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-300 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div
              id="cart"
              v-if="cart.length > 0"
              class="w-full col-span-1 overflow-x-auto shadow-md bg-primary rounded-mine"
            >
              <table class="min-w-full bg-primary">
                <thead>
                  <tr>
                    <th
                      class="px-5 py-3 text-base font-black tracking-normal text-center text-white bg-primary font-mine"
                    >
                      العدد
                    </th>
                    <th
                      class="px-5 py-3 text-base font-black tracking-normal text-center text-white bg-primary font-mine"
                    >
                      السعر
                    </th>
                    <th
                      class="px-5 py-3 text-base font-black tracking-normal text-center text-white bg-primary font-mine"
                    >
                      المنتج
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in cart" v-bind:key="item.id">
                    <td
                      class="px-0 py-5 text-sm bg-white border-b border-gray-200"
                    >
                      <div
                        class="flex items-center justify-start mx-1 lg:justify-center"
                      >
                        <button
                          @click="add(item)"
                          class="text-primary focus:outline-none"
                        >
                          <svg
                            class="w-10 h-10"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                        <p
                          class="mx-2 text-sm font-black text-secondary tabular-nums font-mine"
                        >
                          {{ item.quantity }}
                        </p>
                        <button
                          @click="sub(item)"
                          class="text-primary focus:outline-none"
                        >
                          <svg
                            class="w-10 h-10"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                    <td
                      class="py-5 text-base bg-white border-b border-gray-200"
                    >
                      <div class="flex items-center justify-center">
                        <p
                          dir="rtl"
                          class="text-sm font-black text-center text-secondary tabular-nums font-mine"
                        >
                          {{ currency(item.price * item.quantity) }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="py-5 text-base bg-white border-b border-gray-200"
                    >
                      <div class="flex items-center justify-end">
                        <p
                          class="ml-1 overflow-hidden font-black text-right text-gray-900 whitespace-normal font-mine"
                        >
                          {{ item.name }}
                        </p>
                        <div class="flex-shrink-0 w-10 h-10 mx-2">
                          <img
                            class="w-full h-full rounded-full"
                            :src="item.image.url"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="grid w-full grid-cols-2 py-3 text-base text-center text-white bg-primary font-mine"
              >
                <div
                  dir="rtl"
                  class="text-base font-black tracking-normal text-center font-mine"
                >
                  {{ currency(total) }}
                </div>

                <div
                  class="text-base font-black tracking-normal text-center font-mine"
                >
                  :المجموع
                </div>
              </div>
            </div></transition
          >
        </div>

        <!-- component -->
        <div v-if="!shop.menuMode">
          <div
            class="flex flex-col col-span-1 overflow-hidden bg-white shadow-md rounded-mine"
          >
            <div
              class="w-full py-3 text-base font-black tracking-normal text-center text-white bg-primary font-mine"
            >
              بيانات العميل
            </div>
            <div class="px-4 pt-4 pb-4">
              <div class="mb-4">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  for="name"
                >
                  الاسم
                </label>
                <input
                  v-model="orderDetails.name"
                  dir="rtl"
                  class="block w-full transition-all duration-500 ease-in-out border-gray-300 rounded-md shadow-sm outline-none font-mine focus:ring-secondary focus:border-secondary sm:text-sm"
                  id="name"
                  type="text"
                />
              </div>
              <div class="mb-4">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  for="mobileNumber"
                >
                  رقم الجوال
                </label>
                <input
                  v-model="orderDetails.phoneNumber"
                  class="block w-full transition-all duration-500 ease-in-out border-gray-300 rounded-md shadow-sm outline-none font-mine focus:ring-secondary focus:border-secondary sm:text-sm"
                  id="mobileNumber"
                  placeholder="0500000000"
                  type="tel"
                />
              </div>

              <div v-if="shop.delivery" class="mb-4">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  for="address"
                >
                  العنوان
                </label>
                <input
                  v-model="orderDetails.address"
                  dir="rtl"
                  class="block w-full transition-all duration-500 ease-in-out border-gray-300 rounded-md shadow-sm outline-none font-mine focus:ring-secondary focus:border-secondary sm:text-sm"
                  id="address"
                  type="text"
                />
              </div>
              <div v-if="shop.delivery" dir="rtl" class="mb-4">
                <div
                  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:place-items-center sm:justify-items-center"
                >
                  <div>
                    <div
                      class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    >
                      نوع الطلب
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="max-w-lg">
                      <div class="mt-4 space-y-4">
                        <div class="flex items-center">
                          <input
                            value="استلام من المتجر"
                            v-model="orderDetails.delivery"
                            id="pickupO"
                            type="radio"
                            class="w-4 h-4 border-gray-300 text-secondary focus:ring-secondary"
                          />
                          <label
                            for="pickupO"
                            class="block mr-3 text-sm font-medium font-mine"
                          >
                            استلام من المتجر
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input
                            value="توصيل"
                            v-model="orderDetails.delivery"
                            id="deliveryO"
                            type="radio"
                            class="w-4 h-4 border-gray-300 outline-none text-secondary focus:ring-secondary"
                          />
                          <label
                            for="deliveryO"
                            class="block mr-3 text-sm font-medium font-mine"
                          >
                            توصيل
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label
                v-else
                class="block px-2 mb-4 text-sm font-extrabold text-right text-grey-darker font-mine"
              >
                <span class="">نوع الطلب: </span>
                <span class="text-secondary">استلام من المتجر</span>
              </label>
              <div class="mb-4">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  for="notes"
                >
                  ملاحظات إضافية
                </label>
                <textarea
                  v-model="orderDetails.notes"
                  dir="rtl"
                  class="block w-full transition-all duration-500 ease-in-out border-gray-300 rounded-md shadow-sm outline-none font-mine focus:ring-secondary focus:border-secondary sm:text-sm"
                ></textarea>
              </div>

              <div class="flex flex-col items-center justify-center">
                <button
                  @click="orderModal()"
                  class="px-4 py-2 mb-4 font-black text-white bg-primary font-mine rounded-mine focus:outline-none focus:shadow-none"
                  type="button"
                >
                  اطلب على الواتساب
                </button>
                <div
                  class="m-2 text-sm font-bold tracking-normal text-center text-red-700 font-mine"
                  v-if="errorOrder"
                >
                  {{ errorOrder }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="openMain()"
        class="inset-x-0 bottom-0 py-2 mt-3 text-base font-bold text-center text-white shadow-md bg-primary font-mine"
      >
        احصل على متجرك الخاص على
        <span class="underline text-secondary">منصة كوساين</span>
      </div>
      <div
        @click="goToCart()"
        :class="[cartAnimateState ? 'wobble-ver-left' : '']"
        class="fixed bottom-0 left-0 flex flex-col items-center justify-around w-16 h-16 p-1 mb-6 ml-2 transition duration-500 ease-in-out transform rounded-full md:mb-8 md:ml-4 bg-primary"
      >
        <div class="font-bold text-white">{{ cartItemsCount }}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-grow w-12 h-12 text-white icon icon-tabler icon-tabler-shopping-cart"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="9" cy="19" r="2" />
          <circle cx="17" cy="19" r="2" />
          <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" />
        </svg>
      </div>
    </div>
    <div
      v-else
      class="fixed flex flex-col items-center justify-center w-screen h-screen bg-white z-90"
    >
      <img
        src="@/assets/img/cosinelogo.svg"
        class="w-32 h-32 md:h-40 md:w-40 lg:h-48 lg:w-48 animate-bounce"
      />
    </div>
  </transition>

  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform scale-95 translate-y-4 opacity-0"
    enter-to-class="transform scale-100 translate-y-0 opacity-100"
    leave-active-class="transition duration-300 ease-out"
    leave-from-class="transform scale-100 translate-y-0 opacity-100"
    leave-to-class="transform scale-95 translate-y-4 opacity-0"
  >
    <Modal
      :theme="shop.theme"
      :product="modalItem"
      @hide="modalState = false"
      v-if="modalState"
    ></Modal
  ></transition>
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform scale-95 translate-y-4 opacity-0"
    enter-to-class="transform scale-100 translate-y-0 opacity-100"
    leave-active-class="transition duration-300 ease-out"
    leave-from-class="transform scale-100 translate-y-0 opacity-100"
    leave-to-class="transform scale-95 translate-y-4 opacity-0"
  >
    <CModal
      :theme="shop.theme"
      title="تأكيد الطلب"
      body="هذا الطلب لايعتبر طلب نهائي وستحتاج إلى تأكيد صاحب المتجر من خلال محادثة الواتساب"
      accept="أرسل الطلب"
      @order="order()"
      @hide="orderModalState = false"
      v-if="orderModalState"
    ></CModal
  ></transition>
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform scale-95 translate-y-4 opacity-0"
    enter-to-class="transform scale-100 translate-y-0 opacity-100"
    leave-active-class="transition duration-300 ease-out"
    leave-from-class="transform scale-100 translate-y-0 opacity-100"
    leave-to-class="transform scale-95 translate-y-4 opacity-0"
  >
    <VModal
      :theme="shop.theme"
      :prod="variantItem"
      @order="addVariant"
      @hide="variantModalState = false"
      v-if="variantModalState"
    ></VModal
  ></transition>
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform translate-x-full "
    enter-to-class="transform translate-x-0 "
    leave-active-class="transition duration-300 ease-in"
    leave-from-class="transform translate-x-0 "
    leave-to-class="transform translate-x-full"
  >
    <QR
      v-if="qrModalState"
      :name="shop.name"
      :branch="shop.branch"
      :image="shop.image.url"
      :link="shop.link"
      :theme="shop.theme"
      @hide="QR()"
    ></QR
  ></transition>

  <div
    v-if="orderModalState || modalState || variantModalState"
    class="fixed inset-0 transition-opacity"
  >
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
</template>
<script>
import Modal from "@/components/modal.vue";
import VModal from "@/components/variant_modal.vue";
import CModal from "@/components/confirmation_modal.vue";
import QR from "@/components/qr.vue";
import { db } from "/Users/alialeidmax/Developer/cosine/firebaseConfig.js";
import { an } from "/Users/alialeidmax/Developer/cosine/firebaseConfig.js";
export default {
  mounted: function () {
    this.readShops(this.$route.params.id);
  },

  name: "App",
  components: { Modal, CModal, VModal, QR },
  data() {
    return {
      shop: null,
      selectedCategory: null,
      cart: [],
      total: 0,
      cartItemsCount: 0,
      done: false,
      modalState: false,
      modalItem: null,
      orderModalState: false,
      variantModalState: false,
      variantItem: null,
      qrModalState: false,
      cartAnimateState: false,
      errorOrder: null,
      orderDetails: {
        name: "",
        phoneNumber: "",
        delivery: "استلام من المتجر",
        address: "",
        notes: "",
      },
    };
  },
  computed: {
    filteredProducts: function () {
      return this.shop.products.filter(
        (product) =>
          product.category.id === this.selectedCategory &&
          product.status != "مخفي"
      );
    },
  },

  methods: {
    readShops(link) {
      this.shop = {};
      db.collection("shops")
        .where("link", "==", link)
        .limit(1)
        .get()
        .then((doc) => {
          this.shop = doc.docs[0].data();
          this.selectedCategory = this.shop.categories[0].id;
          window.document.title = this.shop.name;
          this.done = true;
        })
        .catch(() => {
          this.$router.replace({ name: "Home" });
        });
    },

    addItem(prod) {
      if (
        (prod.variants && prod.variants.length > 0) ||
        (prod.addOns && prod.addOns.length > 0)
      ) {
        this.variantItem = prod;
        this.variantModalState = true;
      } else if (prod.status != "غير متوفر") {
        this.cartAnimate();

        this.total += parseFloat(prod.price);
        this.cartItemsCount++;
        let inCart = false;
        // Update quantity if the item is already in the cart
        for (let i = 0; i < this.cart.length; i++) {
          if (this.cart[i].id === prod.id) {
            inCart = true;
            this.cart[i].quantity++;
            break;
          }
        }
        // Add item if not already in the cart
        if (!inCart) {
          this.cart.push({
            id: prod.id,
            name: prod.name,
            price: prod.price,
            image: prod.image,
            quantity: 1,
          });
        }
      }
    },
    addVariant(value) {
      if (value.status != "غير متوفر") {
        this.cartAnimate();

        // Increment total price
        this.total += parseFloat(value.price);
        this.cartItemsCount++;
        let inCart = false;
        // Update quantity if the item is already in the cart
        for (let i = 0; i < this.cart.length; i++) {
          if (this.cart[i].id === value.id) {
            inCart = true;
            this.cart[i].quantity++;
            break;
          }
        }
        // Add item if not already in the cart
        if (!inCart) {
          this.cart.push({
            id: value.id,
            name: value.name,
            price: value.price,
            image: value.image,
            quantity: 1,
          });
        }
        this.variantModalState = false;
      }
    },
    add(item) {
      this.cartAnimate();

      this.total += parseFloat(item.price);
      this.cartItemsCount++;
      item.quantity++;
    },
    sub(item) {
      this.total -= parseFloat(item.price);
      this.cartItemsCount--;
      if (item.quantity > 1) {
        item.quantity--;
      } else {
        for (let i = 0; i < this.cart.length; i++) {
          if (this.cart[i].id === item.id) {
            this.cart.splice(i, 1);
            break;
          }
        }
      }
    },
    cartAnimate() {
      this.cartAnimateState = true;
      setTimeout(() => {
        this.cartAnimateState = false;
      }, 500);
    },
    currency(price) {
      return parseFloat(price).toFixed(2) + " ريال";
    },
    order() {
      let orderText = "%0A";
      this.cart.forEach(function (item) {
        orderText += `%E2%80%8F%E2%97%BE%EF%B8%8Fx${
          item.quantity
        }%E2%80%8F%E2%97%BE%EF%B8%8F%20${item.name}%20%28%20${
          item.quantity * item.price
        }%20%D8%B1%D9%8A%D8%A7%D9%84%20%29%20%0A`;
      });
      let add = "";
      let loc = "";
      if (this.orderDetails.address) {
        add =
          "%0A%E2%96%AB%EF%B8%8F%D8%A7%D9%84%D8%B9%D9%86%D9%88%D8%A7%D9%86%3A%20" +
          this.orderDetails.address;
      } else {
        add = "";
      }
      if (this.shop.social.location) {
        loc =
          "%0A%D9%84%D9%88%D9%83%D9%8A%D8%B4%D9%86%20%D8%A7%D9%84%D9%85%D8%AA%D8%AC%D8%B1%3A%20" +
          this.shop.social.location;
      } else {
        loc = "";
      }
      let note = encodeURI(this.orderDetails.notes);
      let slink = encodeURI(`https://cosine.store/${this.shop.link}`);
      let link =
        "https://api.whatsapp.com/send?phone=966" +
        this.shop.social.whatsapp +
        "&text=" +
        `%D9%85%D8%B1%D8%AD%D8%A8%D8%A7%D9%8B%20%D8%A8%D9%83%20%D9%81%D9%8A%20${this.shop.name}%E2%9D%A4%EF%B8%8F%0A${this.shop.branch}%0A%0A%F0%9F%9B%92%D8%AA%D9%81%D8%A7%D8%B5%D9%8A%D9%84%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8%3A%20%0A` +
        orderText +
        `%0A%D9%85%D8%AC%D9%85%D9%88%D8%B9%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8%3A%20%F0%9F%A7%BE%20%0A${this.total}%20%D8%B1%D9%8A%D8%A7%D9%84%20%F0%9F%92%B5%0A%0A%D9%85%D9%84%D8%A7%D8%AD%D8%B8%D8%A7%D8%AA%3A%20%F0%9F%93%96%0A${note}` +
        `%0A%D9%86%D9%88%D8%B9%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8%3A%20${this.orderDetails.delivery}%0A%0A` +
        `%E2%96%AB%EF%B8%8F%D8%A7%D9%84%D8%A7%D8%B3%D9%85%3A%20${this.orderDetails.name}%0A%E2%96%AB%EF%B8%8F%D8%B1%D9%82%D9%85%20%D8%A7%D9%84%D8%AC%D9%88%D8%A7%D9%84%3A%20${this.orderDetails.phoneNumber}${add}%0A%0A%0A` +
        `%F0%9F%93%8C%20%D9%87%D8%B0%D8%A7%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8%20%D8%B3%D9%88%D9%81%20%D9%8A%D8%AA%D9%85%20%D8%AA%D8%A3%D9%83%D9%8A%D8%AF%D9%87%20%D9%85%D9%86%20%D9%82%D8%A8%D9%84%20%D9%85%D8%AA%D8%AC%D8%B1%20${this.shop.name}%20%0A%0A%D8%B4%D9%83%D8%B1%D8%A7%D9%8B%20%D9%84%D8%B7%D9%84%D8%A8%D9%83%D9%85%E2%9D%A4%EF%B8%8F%0A%0A%D8%B1%D8%A7%D8%A8%D8%B7%20%D8%A7%D9%84%D9%85%D8%AA%D8%AC%D8%B1%3A%20${slink}` +
        loc;
      this.orderModalState = false;
      an.logEvent("order", { store: this.shop.name });

      window.open(link);
    },
    toggleModal(prod) {
      this.modalItem = prod;
      this.modalState = true;
    },
    orderModal() {
      if (this.cart.length > 0) {
        this.errorOrder = null;
        this.orderModalState = true;
      } else {
        this.errorOrder = "السلة فارغة، أضف منتجاً لتتمكن من الطلب";
      }
    },
    goToCart() {
      if (this.cart.length > 0) {
        document.getElementById("cart").scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    },
    openInsta(url) {
      window.open("https://www.instagram.com/" + url);
    },
    openSnap(url) {
      window.open("https://www.snapchat.com/add/" + url);
    },
    openWhatsapp(number) {
      number = "https://api.whatsapp.com/send?phone=966" + number;
      window.open(number);
    },
    openMain() {
      window.open("https://get.cosine.store");
    },
    openLocation(loc) {
      window.open(loc);
    },
    QR() {
      this.qrModalState = !this.qrModalState;
    },
  },
};
</script>
